import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: 'logout',
  templateUrl: './logout.component.html',
})
export class LogoutComponent implements OnInit {

  constructor(public router: Router) {}

  ngOnInit(): void {
    this.logout();
  }

  logout() {
    this.router.navigate(['/login']).then(() => {
      localStorage.clear();
      window.location.reload();
    });
  }
}

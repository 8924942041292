import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { ConstantKeys } from "../constants/constant-keys";

@Injectable()
export class BaseProvider {

    public BASE_URL = environment.base_url;
    public API_KEY = environment.api_key;

    public headers = new HttpHeaders();
    public headersUpload = new HttpHeaders();
    public httpClient: HttpClient;

    public error: Error;
    public status: number;
    public errorMessage: string;

    constructor(public http: HttpClient) {
        this.httpClient = http;
        this.getHeaders();
        this.getHeadersForUpload();
    }

    getHeaders() {
        this.headers = this.headers.set('Content-Type', 'application/json; charset=utf-8');
        let api_key = localStorage.getItem(ConstantKeys.API_KEY);
        if (api_key) {
            this.headers = this.headers.set(ConstantKeys.API_KEY, api_key);
        } else {
            this.headers = this.headers.set(ConstantKeys.API_KEY, environment.api_key);
        }
    }

    addHeader(key: string, value: string) {
        this.headers.set(key, value);
    }

    getHeadersForUpload() {
        this.headersUpload = this.headersUpload.set('Cache-Control', 'no-cache');

        let api_key = localStorage.getItem(ConstantKeys.API_KEY);
        this.headersUpload = this.headersUpload.set(ConstantKeys.API_KEY, api_key);
    }

    getJSONfromModel(model) {
        if (model) {
            return JSON.stringify(model);
        }
    }

    makePostCall(apiEndPoint: string, requestModel: any) {
        const apiUrl = this.BASE_URL + apiEndPoint;
        return this.http
            .post(apiUrl, requestModel, { headers: this.headers, observe: 'response' })
            .pipe(catchError((error) => {
                let errorReason = error?.message;
                if (errorReason == "ACCESS_TOKEN_EXPIRED") {
                    window.location.href = "/logout";
                    return;
                }
                return throwError(error);
            }));
    }

    makePutCall(apiEndPoint: string, requestModel: any) {
        const apiUrl = this.BASE_URL + apiEndPoint;
        return this.http
            .put(apiUrl, this.getJSONfromModel(requestModel), { headers: this.headers, observe: 'response' })
            .pipe(catchError((error) => {
                let errorReason = error?.error?.reason;
                if (errorReason == "ACCESS_TOKEN_EXPIRED") {
                    window.location.href = "/logout";
                    return;
                }
                return throwError(error);
            }));
    }

    makePatchCall(apiEndPoint: string, requestModel: any) {
        const apiUrl = this.BASE_URL + apiEndPoint;
        return this.http
            .patch(apiUrl, this.getJSONfromModel(requestModel), { headers: this.headers, observe: 'response' })
            .pipe(catchError((error) => {
                let errorReason = error?.error?.reason;
                if (errorReason == "ACCESS_TOKEN_EXPIRED") {
                    window.location.href = "/logout";
                    return;
                }
                return throwError(error);
            }));
    }

    makeUploadCall(apiEndPoint: string, requestModel: any) {
        const apiUrl = this.BASE_URL + apiEndPoint;
        return this.http.post(apiUrl, requestModel, { headers: this.headersUpload });
    }

    // makeUploadCall(apiEndPoint: string, requestModel: any) {
    //     const apiUrl = this.BASE_URL + apiEndPoint;
    //     this.headersUpload.delete('Content-Type');
    //     this.headersUpload = this.headersUpload.set('Content-Type', 'multipart/form-data');

    //     console.log("this.headersUpload >>", this.headersUpload);

    //     return this.http
    //         .post(apiUrl, requestModel, { headers: this.headersUpload, observe: 'response' })
    //         .pipe(catchError((error) => {
    //             let errorReason = error?.error?.reason;
    //             if (errorReason == "ACCESS_TOKEN_EXPIRED") {
    //                 window.location.href = "/logout";
    //                 return;
    //             }
    //             return throwError(error);
    //         }));
    // }

    makeGetCall(apiEndPoint: string) {
        const apiUrl = this.BASE_URL + apiEndPoint;
        return this.http
            .get(apiUrl, { headers: this.headers, observe: 'response' })
            .pipe(catchError((error) => {
                console.log("AUTH_VALIDATION_FAILED", error);
                let errorReason = error.error.reason;
                if (errorReason == "AUTH_VALIDATION_FAILED") {
                    window.location.href = "/logout";
                    return;
                }
                return throwError(error);
            }));

    }

    makeDeleteCall(apiEndPoint: string) {
        return this.http
            .delete(this.BASE_URL + apiEndPoint, { headers: this.headers, observe: 'response' })
            .pipe(catchError((error) => {
                let errorReason = error.error.reason;
                if (errorReason == "ACCESS_TOKEN_EXPIRED") {
                    window.location.href = "/logout";
                    return;
                }
                return throwError(error);
            }));
    }
}

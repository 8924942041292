<div class="content">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <img src="assets/images/login.svg" alt="Image" class="img-fluid">
            </div>
            <div class="col-md-6 contents">
                <div class="row justify-content-center">
                    <div class="col-md-8 form-bg p40">
                        <img src="assets/images/gym_master_logo-svg.svg" class="s-logo img-fluid">
                        <div class="mb-4 mt-20">
                            <h5 class="text-center text-black">Reset your password</h5>
                        </div>
                        <form action="#" method="post">
                            <div>
                                <label class="fs-12">Mobile Number</label>
                                <div class="left-inner-addon input-container">
                                    <img src="assets/images/call.svg">
                                    <input type="text" class="form-control" placeholder="Enter Mobile No" />
                                </div>
                            </div>





                            <input type="submit" value="Get Password" class="btn btn-block btn-primary mt-20">

                            <span class="d-block text-center my-4 text-muted">Please <a href="" class="link"> click here </a>to login to your account.</span>

                        </form>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>
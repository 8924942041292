import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbNavModule, NgbAccordionModule, NgbTooltipModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { LayoutsModule } from './layouts/layouts.module';
import { AppComponent } from './app.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layouts/layout.component';
import { ErrorInterceptor } from './core/helpers/error.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './modules/onboarding/login/view/login.component';
import { ResetPasswordComponent } from './modules/onboarding/reset-password/view/reset-password.component';
import { ToastrModule } from 'ngx-toastr';
import { LogoutComponent } from './modules/onboarding/logout/logout.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: '', component: LayoutComponent, loadChildren: () => import('./modules/modules-routing.module').then(m => m.ModulesRoutingModule), },
  // { path: '', component: LayoutComponent, loadChildren: () => import('./modules/modules-routing.module').then(m => m.ModulesRoutingModule), },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    RouterModule.forRoot(routes),
    ToastrModule.forRoot({ positionClass: 'toast-top-center', timeOut: 10000, }),
    HttpClientModule,
    TranslateModule.forRoot({}),
    LayoutsModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTooltipModule,
    ScrollToModule.forRoot(),
    NgbModule,
    FormsModule, 
    ReactiveFormsModule,
    InfiniteScrollModule,
    CommonModule,
    BrowserAnimationsModule
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
})
export class AppModule { }

import { Injectable } from "@angular/core";
import { BaseProvider } from "src/app/shared/services/BaseProvider";
import { LoginRequestModel } from "../models/login-model";
import { HttpClient } from "@angular/common/http";
 

@Injectable()
export class LoginService extends BaseProvider {
    constructor(public http: HttpClient) {
        super(http);
    }

    login(loginRequest: LoginRequestModel) {
        var apiEndPoint = "/login";
        return this.makePostCall(apiEndPoint, loginRequest);
    }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'reset-pwd',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'
]
})

export class ResetPasswordComponent implements OnInit {

  public loginForm: FormGroup;
  public submitted = false;
  public error = '';
  public returnUrl: string;

  public year: number = new Date().getFullYear();

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['admin@themesbrand.com', [Validators.required, Validators.email]],
      password: ['123456', [Validators.required]],
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.router.navigate(['/member-list']);
  }
}

<header id="page-topbar" class="topbar-shadow">
    <div class="navbar-header">
        <div style="display : contents !important">
            <div class="navbar-brand-box">
                <a routerLink="/" class="logo logo-dark">
                    <span class="logo-sm">
                        <img src="assets/images/logo.svg" alt="" height="22">
                    </span>
                    <span class="logo-lg">
                        <img src="assets/images/gym_master_logo-svg.svg" alt="" height="17">
                    </span>
                </a>

                <a routerLink="/" class="logo logo-light">
                    <span class="logo-sm">
                        <img src="assets/images/gym_master_logo-svg.svg" alt="" height="22">
                    </span>
                    <span class="logo-lg">
                        <img src="assets/images/gym_master_logo-svg.svg" alt="" style="height:40px">
                    </span>
                </a>
            </div>


            <div class="topnav">
                <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">
                    <div class="collapse navbar-collapse active" id="topnav-menu-content">
                        <ul class="navbar-nav">
                            <ng-container *ngFor="let item of menuItems, let i = index">
                                <li class="nav-item dropdown" (click)='selectedItem = i;'>
                                    <a [routerLink]="item.link">
                                        <div class="nav-link dropdown-toggle arrow-none" *ngIf="!item.subItems" id="topnav-components" role="button" [ngClass]="{'active-menu': selectedItem === i}">
                                            <i class="bx {{item.icon}} me-2"></i>
                                            <span> {{ item.label | translate }} </span>
                                            <div class="arrow-down" *ngIf="hasItems(item)"></div>
                                        </div>
                                    </a>
                                    <a *ngIf="item.subItems" class="nav-link dropdown-toggle arrow-none" (click)="onMenuClick($event)" href="javascript: void(0);" id="topnav-components" role="button">
                                        <i class="bx {{item.icon}} me-1"></i> {{item.label| translate }}
                                        <div class="arrow-down"></div>
                                    </a>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </nav>
            </div>

            <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item" (click)="toggleMenubar()">
                <i class="fa fa-fw fa-bars"></i>
            </button>
        </div>

        <div class="d-flex">
            <div class="dropdown d-inline-block d-lg-none me-2" ngbDropdown>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu>
                    <form class="p-3">
                        <div class="m-0">
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                                <div class="input-group-append">
                                    <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="dropdown d-inline-block" ngbDropdown>
                <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
                    <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg" alt="Header Avatar">
                    
                </button>
                <img class="rounded-circle header-profile-user" src="assets/images/power-button.png" style="cursor: pointer;" routerLink="/logout">
            </div>
        </div>
    </div>
</header>
<div class="content">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <img src="assets/images/login.svg" alt="Image" class="img-fluid">
            </div>
            <div class="col-md-6 contents">
                <div class="row justify-content-center">
                    <div class="col-md-8 form-bg p40">
                        <img src="assets/images/gym_master_logo-svg.svg" class="s-logo img-fluid">
                        <div class="mb-4 mt-20">
                            <h5 class="text-center text-black">Login to your account</h5>
                        </div>
                        <form action="#" method="post">
                            <div>
                                <label class="fs-12">Username</label>
                                <div class="left-inner-addon input-container">
                                    <img src="assets/images/profile.svg">
                                    <input type="text" class="form-control" placeholder="Username" [(ngModel)]="requestLoginModel.username" name="username" />
                                </div>
                            </div>

                            <div>
                                <label class="fs-12">Password</label>
                                <div class="left-inner-addon input-container">
                                    <img src="assets/images/key.svg">
                                    <input type="password" class="form-control" placeholder="Password" [(ngModel)]="requestLoginModel.password" name="password" />
                                </div>
                            </div>


                            <div class="row mt-10">
                                <div class="col-md-6">
                                    <!-- <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDisabled">
                                        <label class="form-check-label text-muted fs-12">
                                          Remember Me
                                        </label>
                                    </div> -->
                                </div>
                                <div class="col-md-6 text-right">
                                    <label class="form-check-label text-muted fs-12">
                                     <a routerLink="/reset-password">  Forgot Password? </a>
                                      </label>
                                </div>

                            </div>

                            <input type="submit" value="Login" class="btn btn-block btn-primary mt-20" (click)="onSubmit()">

                            <span class="d-block text-center my-4 text-muted fs-11"> &copy; Sreyah Technologies  </span>

                        </form>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>
import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'Dashboard',
        icon: 'bx-home-circle',
        key: 'Dashboard',
        link:'dashboard'
    },
    {
        id: 24,
        label: 'Members',
        icon: 'bx-user',
        key: 'Members',
        link:'member-list'
       
    },
    {
        id: 6,
        label: 'Plans',
        icon: 'bx-book-content',
        isUiElement: true,
        key: 'Plans',
        link:'plan-list'
    },
    
    {
        id: 69,
        icon: 'bx-user',
        label: 'Batches',
        key: 'Batches',
        link:'batch-list'
       
    }
];


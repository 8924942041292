import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../services/login-service';
import { LoginRequestModel } from '../models/login-model';
import { ConstantKeys } from 'src/app/shared/constants/constant-keys';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [LoginService]
})

export class LoginComponent implements OnInit {

  public requestLoginModel = new LoginRequestModel();
  public showLoader : boolean = false;
  public userDetails : any;

    constructor(
    private toast: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
  ) {
    localStorage.clear();
   }

  ngOnInit() {
  }
  
  onSubmit() {
      this.showLoader = true;
      this.loginService.login(this.requestLoginModel).subscribe(resp => {
        var respBody = JSON.parse(JSON.stringify(resp.body));
        this.showLoader = false;
        
        localStorage.setItem(ConstantKeys.USER_DETAILS, JSON.stringify(respBody));
        localStorage.setItem(ConstantKeys.API_KEY, respBody.auth_token);

        this.router.navigate(['/dashboard']);
      }, error => {
        this.toast.error(error);
        this.showLoader = false;
      });
  }
}
